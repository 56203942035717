import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Landing from "./Pages/Landing";

import NavBar from "./Hocs/NavBar";
function App() {
  return (
    <div className="App">
      <Router>
        <NavBar />
        <Switch>
          <Route exact path="/" component={Landing} />
         
        </Switch>
      </Router>
    </div>
  );
}

export default App;
