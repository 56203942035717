
export const props = {
  color: "dark",
  type: "fixed",
  logoImage: "/Assets/LOGO Files/DevThinks-03.jpg",
  logoText: "DevThinks",
  logoLink: { to: "/" },
  socials: [
    {
      icon: "facebook",
      text: "Share on Facebook",
      link: "https://www.facebook.com/devthinks",
    },
    {
      icon: "instagram",
      text: "Follow on Instagram",
      link: "http://instagram.com/dev.thinks/",
    },
    {
      icon: "twitter",
      text: "Tweet on Twitter",
      link: "https://twitter.com/dev_thinks",
    },
    {
      icon: "youtube",
      text: "Channel on YouTube",
      link: "https://www.youtube.com/channel/UCGLPVUEZ_2MSxkPBNkw090g ",
    },
  ],
  leftLinks: [
    { href: "/#services", children: "Services" },
    { href: "/#team", children: "Team" },
    { href: "/#about", children: "About" },
    { href: "/#portfolio", children: "Portfolio" },
    { href: "/#contact", children: "Contact" },
  ],
};
