import NavbarLinks from "@notus-pro/react/NavbarLinks";
import React from "react";
import { props } from "../Data/NavBarProps";

const NavBar = () => {
  return (
    <div>
      <NavbarLinks {...props} />
    </div>
  );
};

export default NavBar;
