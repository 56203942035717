import React from "react"

// @notus-pro/react
import PreFooterSmall from "@notus-pro/react/PreFooterSmall"

const props = {
  cards: [
    {
      icon: "fab fa-react",
      title: "Upwork",
      subtitle: "@devthinks",
      color: "slack",
      link: { href: "https://www.upwork.com/ag/devthinks/" },
    },
    {
      icon: "fab fa-youtube",
      title: "YouTube",
      subtitle: "@devthinks",
      color: "instagram",
      link: { href: "https://www.youtube.com/channel/UCGLPVUEZ_2MSxkPBNkw090g" },
    },
    {
      icon: "fab fa-twitter",
      title: "twitter",
      subtitle: "@dev_thinks",
      color: "twitter",
      link: { href: "http://twitter.com/dev_thinks" },
    },
    {
      icon: "fab fa-facebook-f",
      title: "facebook",
      subtitle: "@devthinks",
      color: "facebook",
      link: { href: "http://facebook.com/devthinks " },
    },
    
  ],
}

export default function Blogs() {
  return (
    <>
    <br/>
      <PreFooterSmall {...props} />
      <br/>
    </>
  )
}
