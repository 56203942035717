import React from "react";
import ContactUs1 from "@notus-pro/react/ContactUs1";
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";

const Contact = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm(
      "service_zrfgjxb",
      "template_whkqmpb",
      e.target,
      "user_KCK8jJj0Jvih67WXFE0N3"
    ).then(res => {
      console.log('Email successfully sent!')
      alert("Email successfully sent!")
    }).catch(err => {alert('Email failed to send!')})
  };
  const [message, setMessage] = React.useState('')
  const [fullName,setFullName] = React.useState('')
  const [email,setEmail] = React.useState('')
  const contactus1 = {
    heading3badge: {
      whiteContent: true,
      badge: { color: "teal", children: "Message us" },
      title: "Type in the bellow form",
      description: "Improve your business with us",
      alignment: "center",
    },
    card: {
      title: "Want to get service?",
      description:
        "Complete this form and we will get back to you in 24 hours.",
      buttonText: "Send Message",
buttonProps:{type:"submit"},
      inputs: [
        {
          labelText: "Full Name",
          inputProps: {
            placeholder: "Full Name",
            type: "text",
            value: fullName ,
            onChange: (e) => setFullName(e.target.value),
            name: "fullname",
            id: "fullname",
            autoComplete: "fullname",
          },
        },
        {
          labelText: "Email",
          inputProps: {
            placeholder: "Email",
            type: "email",
            value:  email ,
            onChange: (e) => setEmail(e.target.value),
            name: "email",
            id: "email",
            autoComplete: "email",
          },
        },
        {
          labelText: "Message",
          inputProps: {
            placeholder: "Type a message...",
            type: "textarea",
            rows: "4",
            cols: "80",
            value:  message ,
            onChange: (e) => setMessage(e.target.value),
            name: "message",
            id: "fullname",
            autoComplete: "fullname",
          },
        },
      ],
    },
  };
  return <form onSubmit={onSubmit}><ContactUs1 {...contactus1} /></form> ;
};

export default Contact;
