import React from "react"

// @notus-pro/react
import Features1 from "@notus-pro/react/Features1"

const props = {
  heading2: {
    color: "emerald",
    subtitle: "Services",
    title: "We are here to provide great Web Solutions",
    description:
      "We are a Freelancing Upwork based Software Agency We deal with website, app, and backend development. ",
    alignment: "right",
  },
  infoArea: {
    icon: "fas fa-rocket",
    iconColor: "lightBlue",
    title: "DevThinks",
    description:
      "We are a globally positioned Software Services company, specializes in Mobile and Web Application development, using latest technologies to develop highly optimized solutions for clients",
      list: [
        { icon: "fas fa-fingerprint", text: "Team of CERTIFIED DEVELOPERS" },
        { icon: "fas fa-pager", text: "TOP-RATED AGENCY By Clients" },
        { icon: "far fa-paper-plane", text: "100% Work Satisfaction Guaranteed" },
      ],
  },
  cards: [
    {
      color: "teal",
      icon: "fas fa-smile-beam",
      title: "Backend Development",
      description:
        "We are providing best backends for websites and mobile apps also.",
      gradient: true,
    },
    {
      color: "red",
      icon: "fas fa-rocket",
      title: "Frontend Development",
      description:
        "Providing you a great UI for grow and improve your business",
      gradient: true,
    },
    {
      color: "dark",
      icon: "fab fa-avianex",
      title: "Mobile App",
      description:
        "We can give you the best apps that running in Native platforms (Android / IOS)",
      gradient: true,
    },
    {
      color: "lightBlue",
      icon: "fab fa-cc-stripe",
      title: "E-commerce Site",
      description:
        "We build fully functional and advanced e-commerce platforms that containing payment portals for you ",
      gradient: true,
    },
    {
      color: "orange",
      icon: "fas fa-glasses",
      title: "Credit Bureaus",
      description:
        "We help you to build awesome credit bureaus that with cool and advanced features",
      gradient: true,
    },
    {
      color: "emerald",
      icon: "fas fa-sitemap",
      title: "WordPress Services",
      description:
        "We providing great wordpress sites for your business also.",
      gradient: true,
    },
  ],
}

export default function Services() {
  return (
    <>
      <Features1 {...props} />
    </>
  )
}
