import React from "react";

import HeaderVideoTitle from "@notus-pro/react/HeaderVideoTitle";

import Contact from "./Contact";
import Footer from "../Hocs/Footer";
import Services from "./Services";
import Team from "./Team";

import Blogs from "./Blogs";
import About2 from "./About2";
import HeaderImageTitleCenter from "@notus-pro/react/HeaderImageTitleCenter";
const props = {
  video: "/Assets/videos/intro-black.mp4",
  title: "Welcome to DevThinks",
  color: "black",
  buttonColor: "light",

  buttonText: "Get in contact",
  buttonIcon: "fas fa-arrow-down",
  buttonLink: "#contact",
  skew: { color: "white", to: "#contact" },
};
const headerimagetitlecenter = {
  bgImage: "/Assets/LOGO Files/DevThinks-03.png",
  title: "Welcome To DevThinks",
  skewColor: "light",
  description: "Where Innovation Meets Excellence",
};
const Landing = () => {
  return (
    <div>
      <main>
        \ <HeaderImageTitleCenter {...headerimagetitlecenter} />
        {/* <div className="-mt-24">
          <Hero1 {...hero1} />
        </div> */}
        {/* <About/> */}
        <div id="services" className="-mt-20">
          <Services />
        </div>
        {/* <div id="team">
          <Team />
        </div> */}
        <div id="about">
          <About2 />
        </div>
        <div id="portfolio">
          <Blogs />
        </div>
        <div id="contact">
          <Contact />
        </div>
      </main>
      <div className="-mt-20">
        <Footer />
      </div>
    </div>
  );
};

export default Landing;
