import React from "react"

// @notus-pro/react
import Hero1 from "@notus-pro/react/Hero1"

const props = {

 
  infoArea: {
    icon: "fas fa-user-friends",
    iconColor: "blueGray",
    title: "Improve Your Business with us",
    description: [
      "We are a globally positioned Software Services company, specializes in Mobile and Web Application development, using latest technologies to develop highly optimized solutions for clients. We are a team of highly talented and dedicated professionals including Programmers, Designers, Project Managers & QA specialists.",
      "❇️ If we will work for you, we'll use the best of our skills to do whatever you need ❇️ Drop a message 📨 Or Invite 🏆 for the task; will be happy to bring best to it through our skills 🤗  ",
    ],
    link: { text: "Check Our Upwork!", link: "/", innerLink: true },
  },
  skewCard: {
    image:
      "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1051&q=80",
    title: "Top Web Development Services",
    description:
      "Best Web Development Solution for your business from an award wining agency. Web Design & Development for your Business. Ecommerce Development. SEO Service.",
    color: "lightBlue",
  },
}

export default function About2() {
  return (
    <>
      <Hero1 {...props} />
    </>
  )
}
