import React from 'react'
import FooterLarge from "@notus-pro/react/FooterLarge";
const footerlarge = {
    title: "Let's keep in touch!",
    description:
      "Find us on any of these platforms, we respond 1-2 business days.",
    copyright: "Copyright © 2021 by DevThinks",
    socials: [
      { icon: "twitter", link: "https://twitter.com/dev_thinks/" },
      { icon: "facebook", link: "https://www.facebook.com/devthinks/" },
      { icon: "youtube", link: "https://www.youtube.com/channel/UCGLPVUEZ_2MSxkPBNkw090g " },
      { icon: "instagram", link: "http://instagram.com/dev.thinks/" },
    ],
    
  };
const Footer = () => {
    return  <FooterLarge {...footerlarge} />;
}

export default Footer